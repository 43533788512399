<template>
  <div>
    <div class="has-background-white has-shadow pa-lg">
      <div class="columns">
        <div class="column is-two-thirds">
          <!-- Report Type -->
          <div class="mb-lg">
            <b-field
              label="Create Report"
              :type="('report_type' in errors) ? 'is-danger' : ''"
              :message="('report_type' in errors) ? errors['model'][0] : ''">
              <b-select
                v-model="reportType"
                placeholder="Billing Model"
                expanded>
                <option value="burn">Utilization Report</option>
              </b-select>
            </b-field>
          </div>
          <!-- Date Range -->
          <div class="mb-md">
            <div class="columns is-multiline">
              <div class="column is-half">
                <p class="has-text-weight-bold mb-md">Start Date</p>
                <b-field>
                  <b-datepicker
                    type="month"
                    v-model="startBillingPeriod"
                    placeholder="Select start date..."
                    icon="calendar-today"
                    trap-focus
                    :min-date="$dates().startOf('year').subtract(2, 'year').toDate()"
                    :max-date="$dates().startOf('month').toDate()"
                    expanded>
                  </b-datepicker>
                </b-field>
              </div>
              <div class="column is-half">
                <p class="has-text-weight-bold mb-md">End Date</p>
                <b-field>
                  <b-datepicker
                    type="month"
                    v-model="endBillingPeriod"
                    placeholder="Select end date..."
                    icon="calendar-today"
                    trap-focus
                    :min-date="$dates(startBillingPeriod).toDate()"
                    :max-date="$dates().startOf('month').toDate()"
                    expanded>
                  </b-datepicker>
                </b-field>
              </div>
            </div>
          </div>
          <!-- Orgs  -->
          <div class="mb-lg">
            <p class="mb-sm"><label for="" class="label">Organizations</label></p>
            <div class="border pt-md px-md" style="max-height: 400px; overflow: auto;">
              <b-table
                :data="clients"
                :mobile-cards="false"
                :checked-rows.sync="selectedClients"
                hoverable
                narrowed
                checkable>
                <b-table-column
                  label="Client"
                  v-slot="props"
                  cell-class="nowrap">
                  {{ props.row.name }} <span class="has-text-grey-light">(ID: {{ props.row.client_id }})</span>
                </b-table-column>
              </b-table>
            </div>
          </div>
          <!-- Client  -->
          <div class="mb-lg">
            <p class="mb-sm"><label for="" class="label">Clients</label></p>
            <div class="border pt-md px-md" style="max-height: 400px; overflow: auto;">
              <b-table
                :data="clients"
                :mobile-cards="false"
                :checked-rows.sync="selectedClients"
                hoverable
                narrowed
                checkable>
                <b-table-column
                  label="Client"
                  v-slot="props"
                  cell-class="nowrap">
                  {{ props.row.name }} <span class="has-text-grey-light">(ID: {{ props.row.client_id }})</span>
                </b-table-column>
              </b-table>
            </div>
          </div>
          <!-- Project  -->
          <div class="mb-lg">
            <p class="mb-sm"><label for="" class="label">Projects</label></p>
            <div class="border pt-md px-md" style="max-height: 400px; overflow: auto;">
              <b-table
                :data="projects"
                :mobile-cards="false"
                :checked-rows.sync="selectedProjects"
                hoverable
                narrowed
                checkable>
                <b-table-column
                  label="Project"
                  v-slot="props"
                  cell-class="nowrap">
                  {{ props.row.name }} <span class="has-text-grey-light">(ID: {{ props.row.id }})</span>
                </b-table-column>
              </b-table>
            </div>
          </div>
          <!-- Inclusions  -->
          <div class="mb-md">
            <p class="mb-md"><label for="" class="label">Include</label></p>
            <div class="columns">
              <div class="column">
                <div>
                  <p class="mb-md"><label for="" class="label is-size-7">Member Details</label></p>
                  <b-field><b-checkbox v-model="dimensions" native-value="organization">Organization</b-checkbox></b-field>
                  <b-field><b-checkbox v-model="dimensions" native-value="client">Client</b-checkbox></b-field>
                  <b-field><b-checkbox v-model="dimensions" native-value="project">Project</b-checkbox></b-field>
                  <b-field><b-checkbox v-model="dimensions" native-value="budget">Budget</b-checkbox></b-field>
                  <b-field><b-checkbox v-model="dimensions" native-value="contributor">Member Name</b-checkbox></b-field>
                  <b-field><b-checkbox v-model="dimensions" native-value="title">Title</b-checkbox></b-field>
                  <b-field><b-checkbox v-model="dimensions" native-value="role">Role</b-checkbox></b-field>
                  <b-field><b-checkbox v-model="dimensions" native-value="billingPeriod">Billing Period</b-checkbox></b-field>
                </div>
              </div>
              <div class="column">
                <div>
                  <p class="mb-md"><label class="label is-size-7">Rate Details</label></p>
                  <b-field><b-checkbox v-model="dimensions" native-value="billRate">Client Rate</b-checkbox></b-field>
                  <b-field><b-checkbox v-model="dimensions" native-value="costRate">Cost Rate</b-checkbox></b-field>
                </div>
              </div>
              <div class="column">
                <div>
                  <p class="mb-md"><label class="label is-size-7">Hours and Costs</label></p>
                  <b-field><b-checkbox v-model="dimensions" native-value="actualHours" disabled>Actual Hours</b-checkbox></b-field>
                  <b-field><b-checkbox v-model="dimensions" native-value="actualBudget" disabled>Actual Cost</b-checkbox></b-field>
                </div>
              </div>
            </div>
          </div>
          <b-button type="is-primary is-medium" @click="runReport">Run Report</b-button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import BillingPeriodSelect from '@/components/Models/BillingPeriod/SelectMenu'

export default {
  components: {
    BillingPeriodSelect,
  },
  computed: {
    ...mapGetters(['api', 'context']),
    clientsQuery () {
      const ids = []
      if (this.selectedClients.length) {
        this.selectedClients.forEach(client => {
          ids.push(client.client_id)
        })
      }
      return (ids.length) ? ids.join(',') : null
    },
    projectsQuery () {
      const ids = []
      if (this.selectedProjects.length) {
        this.selectedProjects.forEach(project => {
          ids.push(project.id)
        })
      }
      return (ids.length) ? ids.join(',') : null
    }
  },
  data () {
    return {
      loadingClients: false,
      loadingProjects: false,
      reportType: 'burn',
      startBillingPeriod: this.$dates().startOf('year').toDate(),
      endBillingPeriod: this.$dates().startOf('month').toDate(),
      startOfYear: this.$dates().startOf('year').format('YYYY-MM-DD'),
      endOfYear: this.$dates().endOf('year').format('YYYY-MM-DD'),
      startBillingPeriodId: 1031,
      endBillingPeriodId: 1042,
      clients: [],
      projects: [],
      selectedProjects: [],
      selectedClients: [],
      includeMember: true,
      includeTitle: true,
      includeRole: true,
      includeBillRate: true,
      includeCostRate: true,
      includeAllocatedHours: true,
      includeAllocatedBurn: true,
      includeAllcoatedCosts: true,
      includeActualHours: true,
      includeActualBurn: true,
      includeActualCosts: true,
      dimensions: [],
      rates: [],
      metrics: [],
      errors: {}
    }
  },
  methods: {
    loadOrganizations () {
      this.selectedClients = []
      this.selectedProjects = []
      this.loadingClients = true
      const endpoint = this.api.route + '/reports/clients'
      const query = {
        params: {
          start_date: this.$dates(this.startBillingPeriod).format('YYYY-MM-DD'),
          end_date: this.$dates(this.endBillingPeriod).format('YYYY-MM-DD')
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.clients = response.data
        this.loadingClients = false
      })
    },
    loadClients () {
      this.selectedClients = []
      this.selectedProjects = []
      this.loadingClients = true
      const endpoint = this.api.route + '/reports/clients'
      const query = {
        params: {
          start_date: this.$dates(this.startBillingPeriod).format('YYYY-MM-DD'),
          end_date: this.$dates(this.endBillingPeriod).format('YYYY-MM-DD')
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.clients = response.data
        this.loadingClients = false
      })
    },
    loadProjects () {
      this.selectedProjects = []
      this.loadingProjects = true
      const endpoint = this.api.route + '/reports/projects'
      const query = {
        params: {
          start_date: this.$dates(this.startBillingPeriod).format('YYYY-MM-DD'),
          end_date: this.$dates(this.endBillingPeriod).format('YYYY-MM-DD'),
          clients: this.clientsQuery
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.projects = response.data
        this.loadingProjects = false
      })
    },
    runReport () {
      const endpoint = this.api.route + '/reports/run'
      const query = {
        start_date: this.$dates(this.startBillingPeriod).format('YYYY-MM-DD'),
        end_date: this.$dates(this.endBillingPeriod).format('YYYY-MM-DD'),
        dimensions: this.dimensions,
        clients: this.clientsQuery,
        projects: this.projectsQuery
      }
      axios({
        url: endpoint,
        params: query,
        method: 'GET',
        responseType: 'blob' // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'report.xlsx')
        document.body.appendChild(link)
        link.click()
      })
    }
  },
  mounted () {
    this.dimensions = ['organization', 'client', 'project', 'budget', 'contributor', 'title', 'role', 'billRate', 'costRate', 'allocatedHours', 'allocatedBudget', 'allocatedCost', 'actualHours', 'actualBudget', 'actualCost', 'billingPeriod']
    this.loadClients()
  },
  watch: {
    clientsQuery () {
      this.loadProjects()
    },
    startBillingPeriod () {
      this.loadClients()
    },
    endBillingPeriod () {
      this.loadClients()
    }
  }
}
</script>

<template>
<div>
  <p class="has-text-primary has-text-weight-bold">Contributor Summary</p>
  <b-table
    v-if="Object.keys(contributors).length"
    :data="contributors"
    :mobile-cards="false"
    hoverable
    narrowed
    class="is-size-7">
    <b-table-column
      label="Contributor"
      v-slot="props"
      header-class="is-size-7"
      sortable
      field="contributor"
      default-sort>
      <p>{{ props.row.contributor }}</p>
    </b-table-column>
    <b-table-column
      label="Avg Bill Rate ($)"
      centered
      width="128"
      header-class="is-size-8"
      v-slot="props"
      sortable
      field="bill_rate">  
      <p><Currency :value="props.row.bill_rate" /></p>
    </b-table-column>
    <b-table-column
      label="Avg Cost Rate ($)"
      centered
      width="128"
      header-class="is-size-8"
      v-slot="props"
      sortable
      field="cost_rate">
      <p><Currency :value="props.row.cost_rate" /></p>
    </b-table-column>
    <b-table-column
      label="Billable (Hrs)"
      centered
      width="128"
      header-class="is-size-8"
      v-slot="props"
      sortable
      field="hours_billable">
      <p><Hours :hours="props.row.hours_billable" /></p>
    </b-table-column>
    <b-table-column
      label="Billable ($)"
      centered
      width="128"
      header-class="is-size-8"
      v-slot="props"
      sortable
      field="billable_total">
      <p><Currency :value="props.row.billable_total" /></p>
    </b-table-column>
    <b-table-column
      label="Cost ($)"
      header-class="is-size-8"
      centered
      width="128"
      v-slot="props"
      sortable
      field="cost">
      <p><Currency :value="props.row.cost" /></p>
    </b-table-column>
  </b-table>
  <div v-else>
    <p class="has-text-grey-light is-size-7">No contributor activity is reported for this period.</p>
  </div>
</div>
</template>

<script>
export default {
  computed: {
    contributors () {
      const rows = []
      const contributors = {}
      this.project.positions.forEach(budget => {
        budget.seats.forEach(seat => {
          if ('timesheets' in seat && seat.timesheets.length === 1) {
            if (seat.partner_role_id in contributors) {
              contributors[seat.partner_role_id].hours_billable += Number(seat.timesheets[0].hours_billable)
              contributors[seat.partner_role_id].billable_total += Number(seat.timesheets[0].billable_total)
              contributors[seat.partner_role_id].cost += Number(seat.timesheets[0].cost)
            } else {
              contributors[seat.partner_role_id] = {
                contributor: (seat.contributor.first_name) ? seat.contributor.first_name + ' ' + seat.contributor.last_name : seat.contributor.user.full_name,
                hours_billable: Number(seat.timesheets[0].hours_billable),
                billable_total: Number(seat.timesheets[0].billable_total),
                cost: Number(seat.timesheets[0].cost)
              }
            }
          }
        })
      })
      for (const key in contributors) {
        contributors[key].bill_rate = Number(contributors[key].billable_total / contributors[key].hours_billable)
        contributors[key].cost_rate = Number(contributors[key].cost / contributors[key].hours_billable)
        rows.push(contributors[key])
      }
      return rows
    }
  },
  props: {
    period: {
      type: Object,
      required: true
    },
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
